import {Grid, Typography} from '@mui/material';
import BulletPointIcon from '../assets/bullet-point-icon.svg';
import React from 'react';
import {Service} from '../@types/CMSData';
import useMobile from './useMobile';
import MuiMarkdown from 'mui-markdown';

export default function ServiceDetails({service}: {service: Service}) {
  const isMobile = useMobile();
  const bulletSize = isMobile ? '1.4rem' : '1.6rem';
  return (<Grid container spacing={3} direction={'column'}>
    <Grid item>
      <Grid container wrap={'nowrap'} alignItems={'center'} spacing={1.5}>
        <Grid item>
          <div style={{
            width: bulletSize,
            height: bulletSize,
            backgroundImage: `url('${BulletPointIcon}')`,
            backgroundPosition: 'center',
            backgroundRepeat: 'none'
          }} />
        </Grid>
        <Grid item>
          <Typography variant={'h4'}>{service.title}</Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Typography variant={'body1'}>
        <MuiMarkdown options={{forceWrapper: false}}>{service.description}</MuiMarkdown>
      </Typography>
    </Grid>
  </Grid>);
}
