import React from 'react';
import SkewedPanel from './SkewedPanel';
import {Container, Grid, Typography} from '@mui/material';
import ServiceDetails from './ServiceDetails';
import {Service} from '../@types/CMSData';


function ServicesPage({services}: {services: Service[]}) {
  return (
    <>
      <SkewedPanel variant={'dark'} noTopMargin={true} id={'vse'}>
        <Container><Typography align={'center'} variant={'h1'} color={'white'}>Naše storitve</Typography></Container>
      </SkewedPanel>
      <Container maxWidth={'md'} sx={{pt: '50px'}}>
        <Grid container spacing={5} direction={'column'}>
          {services.map((service, i) => <Grid item key={i}><ServiceDetails service={service} /></Grid>)}
        </Grid>
      </Container>
    </>)
    ;
}

export default ServicesPage;
